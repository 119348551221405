import {RouteRecordRaw} from 'vue-router';
import Auth from 'src/router/routes/auth';
import Subscription from 'src/apps/subscription/routes';
import Messenger from 'src/apps/messenger/routes';
import Search from 'src/apps/search/routes';
import Landing from 'src/router/routes/landing';
import Tools from 'src/router/routes/tools';
import Business from 'src/router/routes/business';
import Widgets from 'src/router/routes/widgets';
import Payment from 'src/router/routes/payment';
import Advert from 'src/router/routes/advert';

let routes: RouteRecordRaw[] = []

routes = [
  {
    path: '',
    component: () => import('src/apps/search/layouts/SearchWrapperLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('layouts/AppMainLayout.vue'),
        children: [
          {
            path: '',
            component: () => import('src/apps/search/pages/SearchPage.vue'),
            name: 'search',
            meta: {noHeader: true}
          },
          {
            path: '/karte',
            alias: '/map',
            component: () => import('pages/MapPage.vue'),
            name: 'map',
            meta: {noFooter: true, noFooterBar: true, noHeader: true}
          },
        ]
      },
    ],
  },

  {
    path: '',
    component: () => import('layouts/AppMainLayout.vue'),
    children: [
      {
        path: '/favoriten',
        alias: '/favorites',
        component: () => import('src/pages/FavoritesPage.vue'),
        name: 'favorites',
        meta: {loginRequired: true}
      },

      {
        path: '/login',
        component: () => import('src/pages/auth/LoginPage.vue'),
        name: 'login',
      },

      {
        path: '/ueber-uns',
        alias: '/about-us',
        component: () => import('pages/static/AboutUsPage.vue'),
        name: 'about-us',
      },
      {
        path: '/partner',
        component: () => import('pages/static/PartnerPage.vue'),
        name: 'partner',
      },
      {
        path: '/faq-hilfe',
        alias: '/faq-help',
        component: () => import('pages/static/FaqAndHelpPage.vue'),
        name: 'faq-help',
      },
      {
        path: '/datenschutz',
        alias: '/privacy',
        component: () => import('pages/static/PrivacyPage.vue'),
        name: 'privacy',
      },
      {
        path: '/impressum',
        alias: '/imprint',
        component: () => import('pages/static/ImprintPage.vue'),
        name: 'imprint',
      },
      {
        path: '/kontakt',
        alias: '/contact',
        component: () => import('pages/static/ContactPage.vue'),
        name: 'contact',
      },
    ],
  },

  {
    path: '',
    component: () => import('src/apps/core/layouts/FullPageAppLayout.vue'),
    children: [
      {
        path: '/haendler-werden',
        alias: '/dealer',
        component: () => import('pages/static/landingPages/BusinessLandingPage.vue'),
        name: 'dealer-landing',
      },
    ]
  },
]

routes = routes.concat(Auth)
routes = routes.concat(Advert)
routes = routes.concat(Subscription)
routes = routes.concat(Landing)
routes = routes.concat(Tools)
routes = routes.concat(Business)
routes = routes.concat(Widgets)
routes = routes.concat(Payment)
routes = routes.concat(Payment)
routes = routes.concat(Messenger)
routes = routes.concat(Search)

routes.push(
  {
    path: '',
    component: () => import('layouts/BackBtnLayout.vue'),
    children: [
      {
        path: '/redirect',
        component: () => import('pages/ExternalLinkRedirect.vue'),
        name: 'redirect',
      },

      {
        path: '/gewinnspiel/contest-summer-special-2024',
        component: () => import('src/apps/etc/pages/ContestPage.vue'),
        name: 'contest-summer-special-2024',
        meta: {loginRequired: true}
      }
    ]
  },
)

routes.push({
  path: '/download-app',
  component: () => import('src/apps/core/pages/AppDownloadRedirect.vue'),
  name: 'download-app'
})

if (process.env.DEV) {
  routes.push({
      path: '',
      component: () => import('layouts/BackBtnLayout.vue'),
      children: [
        {
          path: '/dev',
          name: 'developer',
          component: () => import('src/pages/DevPage.vue')
        }
      ],
    }
  )
}
export default routes
