import {CategoryItem} from 'src/apps/search/types';


const catVehicle: Array<CategoryItem> = [
  {label: 'Vom Händler', name: 'business', icon: 'fas fa-briefcase'},
  {label: 'Autark', name: 'autark', icon: 'fas fa-tent'},
  {label: 'Winter', name: 'winterSport', icon: 'fas fa-mountain'},
  {label: 'Familie', name: 'family', icon: 'fas fa-people-group'},
  {label: '4x4', name: '4x4', icon: 'fas fa-truck-monster'},
  {label: 'Luxus', name: 'luxurious', icon: 'fas fa-dollar-sign'},
  {label: 'Remote Work', name: 'remoteWork', icon: 'fas fa-briefcase'},
  {label: 'Campervan', name: 'campervan', image: require('src/assets/camper_type_icons/icon_camper.svg')},
  {label: 'Tiny Camper', name: 'tiny_camper', image: require('src/assets/camper_type_icons/icon_tiny_camper.svg')},
  {label: 'Alkoven', name: 'alkoven', image: require('src/assets/camper_type_icons/icon_camper.svg')},
  {
    label: 'Integrierter',
    name: 'integrierter',
    image: require('src/assets/camper_type_icons/icon_wohnmobil_integriert.svg'),
  },
  {
    label: 'Teilintegrierter',
    name: 'teilintegrierter',
    image: require('src/assets/camper_type_icons/icon_wohnmobil_integriert.svg')
  },
  {label: 'Wohnwagen', name: 'wohnwagen', image: require('src/assets/camper_type_icons/icon_wohnwagen.svg')},
  {label: 'Selbstausbau', name: 'ownBuild', icon: 'fas fa-tools'},
  {label: 'Oldtimer', name: 'oldtimer', image: require('src/assets/camper_type_icons/recreational-vehicle.png')},
  {label: 'Liner', name: 'liner', image: require('src/assets/camper_type_icons/recreational-vehicle.png')},
  {label: 'Andere', name: 'other', image: require('src/assets/camper_type_icons/recreational-vehicle.png')},
  // {label: 'Wassersport', name: 'waterSport', icon: 'fas fa-water'},
  // {label: 'Festival', name: 'festival', icon: 'fas fa-guitar'},
  // {label: 'Barrierefrei', name: 'wheelchair', icon: 'fas fa-wheelchair'},
  // {label: 'Elektrisch', name: 'electric', icon: 'fa-solid fa-plug'},
  // {label: 'Öko', name: 'eco', icon: 'fas fa-leaf'},

]

const catDealer: Array<CategoryItem> = [
  {label: 'Händler', name: 'dealer'},
  {label: 'Vermieter', name: 'converter'},
  {label: 'Ausbauer', name: 'rentals'},
  {label: 'Shop', name: 'shop'},
  {label: 'Module', name: 'modules'},
  {label: 'Werkstatt', name: 'workshop'},
  {label: 'Nachrüstung', name: 'retrofit'},
  {label: 'TÜV und HU', name: 'tuv'},
]

const catService: Array<CategoryItem> = [
  {label: 'Werkstatt', name: '1', icon: 'fas fa-wrench'},
  {label: 'Nachrüstung', name: '2', image: require('src/assets/camper_type_icons/recreational-vehicle.png')},
  {label: 'TÜV und HU', name: '3', image: require('src/assets/camper_type_icons/recreational-vehicle.png')},
  {label: 'Elektronik', name: '4', image: require('src/assets/camper_type_icons/recreational-vehicle.png')},
]

export const categories = {
  vehicle: catVehicle,
  dealer: catDealer,
  service: catService
}

export const valueFilterMapping = {
  vehicle: {
    business: {
      is_business: true
    },
    autark: {
      vehicle__extras__slug: ['battery', 'solar', 'water_tank', 'toilet']
    },
    winterSport: {
      vehicle__extras__slug: ['heater', 'hot_water',]
    },
    family: {
      vehicle__seats_min: 4,
      vehicle__beds_min: 4,
    },
    luxurious: {
      price_min: 100_000,
    },

    all: {
      vehicle__car_type: undefined,
      vehicle__all_wheel_drive: undefined,
      vehicle__is_oldtimer: undefined,
      vehicle__extras__slug: []
    },
    campervan: {vehicle__car_type: 'campervan', vehicle__all_wheel_drive: undefined, vehicle__is_oldtimer: undefined},
    tiny_camper: {
      vehicle__car_type: 'tiny_camper',
      vehicle__all_wheel_drive: undefined,
      vehicle__is_oldtimer: undefined
    },
    alkoven: {vehicle__car_type: 'alkoven', vehicle__all_wheel_drive: undefined, vehicle__is_oldtimer: undefined},
    integrierter: {
      vehicle__car_type: 'integrierter',
      vehicle__all_wheel_drive: undefined,
      vehicle__is_oldtimer: undefined
    },
    teilintegrierter: {
      vehicle__car_type: 'teilintegrierter',
      vehicle__all_wheel_drive: undefined,
      vehicle__is_oldtimer: undefined
    },
    wohnwagen: {vehicle__car_type: 'wohnwagen', vehicle__all_wheel_drive: undefined, vehicle__is_oldtimer: undefined},
    '4x4': {vehicle__all_wheel_drive: true, vehicle__car_type: undefined, vehicle__is_oldtimer: undefined},
    remoteWork: {
      vehicle__extras__slug: ['battery', 'inverter'],
    },
    oldtimer: {vehicle__is_oldtimer: true, vehicle__car_type: undefined, vehicle__all_wheel_drive: undefined},
    liner: {vehicle__car_type: 'liner', vehicle__all_wheel_drive: undefined, vehicle__is_oldtimer: undefined},
    other: {vehicle__car_type: 'other', vehicle__all_wheel_drive: undefined, vehicle__is_oldtimer: undefined}
  },
  dealer: {
    all: {business_types: undefined},
    dealer: {business_types: 'dealer'},
    converter: {business_types: 'converter'},
    rentals: {business_types: 'rentals'},
    shop: {business_types: 'shop'},
    modules: {business_types: 'modules'},
    workshop: {business_types: 'workshop'},
    retrofit: {business_types: 'retrofit'},
    tuv: {business_types: 'tuv'},
  },
  service: {
    test: {test: 'test'}
  }
}
