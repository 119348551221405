import {useGtag} from 'vue-gtag-next';


export const useTracking = () => {

  function checkWindow() {
    if (!window) {
      throw 'no window is defined'
    }
  }

  return {
    trackUserSignup: (email: string, uuid: string | undefined = undefined) => {
      checkWindow()
      console.debug('trackUserSignup')

      // matomo
      try {
        const _paq = (window as any)._paq || []
        _paq.push(['trackEvent', 'User', 'Signup', email]);
      } catch (e) {
        console.error(e)
      }

      // google analytics
      try {
        const {event} = useGtag() as any
        event('sign_up_user', {
          'event_category': 'engagement',
          'event_label': 'method',
        })
      } catch (e) {
        console.error(e)
      }

      // facebook pixel
      try {
        if (window?.fbq) {
          window.fbq('track', 'CompleteRegistration', {content_name: `${uuid}`, content_type: 'User'})
        }
      } catch (e) {
        console.error(e)
      }
    },

    trackAdvertInteractionMessage() {
      checkWindow()
      console.debug('trackAdvertInteractionMessage')

      // matomo
      try {

        const _paq = (window as any)._paq || []
        _paq.push(['trackEvent', 'AdvertInteraction', 'SendMessage']);
      } catch (e) {
        console.error(e)
      }

      // google analytics
      try {
        const {event} = useGtag() as any
        event('advert_interaction', {
          'event_source': 'message',
          'event_category': 'engagement',
          'event_label': 'method',
        })
      } catch (e) {
        console.error(e)
      }

      try {
        // facebook pixel
        if (window?.fbq) {
          window.fbq('track', 'Lead', {content_name: 'Send Message', content_category: 'AdvertLead'})
        }
      } catch (e) {
        console.error(e)
      }

    },

    trackAdvertInteractionPhone() {
      checkWindow()
      console.debug('trackAdvertInteractionPhone')

      // matomo
      try {

        const _paq = (window as any)._paq || []
        _paq.push(['trackEvent', 'AdvertInteraction', 'ShowPhone']);
      } catch (e) {
        console.error(e)
      }


      // google analytics
      try {
        const {event} = useGtag() as any
        event('advert_interaction', {
          'event_source': 'phone',
          'event_category': 'engagement',
          'event_label': 'method',
        })
      } catch (e) {
        console.error(e)
      }


      // facebook pixel
      try {
        if (window?.fbq) {
          window.fbq('track', 'Lead', {content_name: 'Show Phone', content_category: 'AdvertLead'})
        }
      } catch (e) {
        console.error(e)
      }

    },

    trackBusinessSignup(businessName: string) {
      checkWindow()
      console.debug('trackBusinessSignup')

      // matomo
      const _paq = (window as any)._paq || []
      _paq.push(['trackEvent', 'Business', 'Signup', businessName]);

      // google analytics
      const {event} = useGtag() as any
      event('sign_up_business', {
        'event_category': 'engagement',
        'event_label': 'method',
      })
      // facebook pixel

      if (window?.fbq) {
        window.fbq('track', 'CompleteRegistration', {content_name: `${businessName}`, content_type: 'Business'})
      }
    },


    trackAdClick(name: string) {
      checkWindow()
      console.debug('trackAdClick', name)

      // matomo
      try {
        const _paq = (window as any)._paq || []
        _paq.push(['trackEvent', 'Ad', 'Click', name]);
      } catch (e) {
        console.error(e)
      }


      // google analytics
      try {
        const {event} = useGtag() as any
        event('advert_interaction', {
          'event_source': name,
          'event_category': 'engagement',
          'event_label': 'method',
        })
      } catch (e) {
        console.error(e)
      }


      // facebook pixel
      try {
        if (window?.fbq) {
          window.fbq('track', 'Lead', {content_name: name, content_category: 'Ad'})
        }
      } catch (e) {
        console.error(e)
      }

    }
  }
}
