export default [
  {
    path: '',
    component: () => import('layouts/AppMainLayout.vue'),
    children: [
      {
        path: '/advert/:slug?/:uuid/',
        component: () => import('pages/AdvertDetailPage.vue'),
        name: 'listingDetail',
        meta: {showFeedback: true, hasBackBtn: true, noFooterBar: true},
      },
      {
        path: '/advert/create/',
        component: () => import('pages/advert/CreateUpdateAdvertPage.vue'),
        name: 'createAdvert',
        meta: {loginRequired: true, noFooter: true},
      },
      {
        path: '/advert/finish/:uuid/',
        component: () => import('pages/advert/CreateFinishScreen.vue'),
        name: 'createAdvertFinish',
        meta: {loginRequired: true, noFooter: true},
      },
      {
        path: '/advert/edit/:uuid/',
        component: () => import('pages/advert/CreateUpdateAdvertPage.vue'),
        name: 'editAdvert',
        meta: {loginRequired: true},
      },
    ],
  },
]
