import {createMemoryHistory, createRouter, createWebHashHistory, createWebHistory,} from 'vue-router';
import routes from './routes';
import routesApp from './routes-app';
import {useMainStore} from 'stores/main';
import {Cookies, Platform} from 'quasar';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default ({store, ssrContext}) => {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory);

  const platform = process.env.SERVER
    ? Platform.parseSSR(ssrContext)
    : Platform // otherwise we're on client

  const cookies = process.env.SERVER
    ? Cookies.parseSSR(ssrContext)
    : Cookies // otherwise we're on client

  let _routes, isForceApp: boolean
  try {
    isForceApp = !!process.env.FORCE_APP
  } catch (e) {
    isForceApp = false
  }
  if (isForceApp || platform.is.capacitor || platform.is.cordova) {
    _routes = routesApp
  } else {
    _routes = routes
  }

  // Check if quasar app is capacitor here

  const Router = createRouter({
    scrollBehavior: (to, from, savedPosition) => {
      return savedPosition || {left: 0, top: 0}
    },
    routes: _routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(
      process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE
    ),
  });

  Router.beforeEach((to, from, next) => {
    const mainStore = useMainStore(store)
    mainStore.siteLoading = false

    if (!to.meta?.noSiteLoading) {
      mainStore.setSiteLoading(true, 400)
    }

    if (to.meta?.loginRequired && (to.name !== 'login' && !mainStore.isLoggedIn && !cookies.has('token'))) {
      next({name: 'login', query: {n: to.fullPath, required: 'yes'}})
    } else next()
  })

  Router.afterEach((/*to, from*/) => {
    const mainStore = useMainStore(store)
    mainStore.setSiteLoading(false)
  })


  return Router;
}
