import {defineStore} from 'pinia';
import Adverts from 'src/api/adverts';

export const useAdvertStore = defineStore('adverts', {

  state: () => ({
    advertOptions: undefined as any | undefined,
  }),

  actions: {
    async fetchAdvertOptions() {
      if (this.advertOptions !== undefined) return this.advertOptions

      return Adverts.getAdvertOptions().then(resp => {
        this.advertOptions = resp.data.actions.POST
        return resp
      }).catch(err => {
        console.error(err)
      })
    }
  }

})
