import {Extra, VehicleExtra} from 'src/models/vehicle';
import {Advert} from 'src/models/advert';
import Adverts from 'src/api/adverts';
import {Dialog, Loading, QSpinnerGears} from 'quasar';
import {api} from 'boot/axios';
import {getMessaging, getToken} from 'firebase/messaging';
import {firebase} from 'boot/firebase';
import {formatDistance} from 'date-fns'

import {useMainStore} from 'stores/main';
import {de} from 'date-fns/locale';
import {useSubscriptionStore} from 'src/apps/subscription/store';
import LoginSignupDialog from 'src/apps/auth/dialogs/LoginSignupDialog.vue';
import {RouteRecordRaw} from 'vue-router';
import {Store} from 'pinia';


const mapping = [
  {
    name: 'Elektrik',
    extraSlugs: ['battery', 'generator', 'inverter', 'booster', 'solar', 'shore_power_socket'],
  },
  {
    name: 'Sanitär',
    extraSlugs: ['shower', 'toilet', 'water_tank', 'greywater_drain', 'hot_water', 'heater', 'clean_water_socket', 'outdoor_shower', 'water_filter_system']
  },
  {
    name: 'Küche',
    extraSlugs: ['oven', 'stove', 'sink', 'microwave', 'fridge', 'table']
  },
  {
    name: 'Entertainment',
    extraSlugs: ['television', 'satellite_dish', 'sound_system']
  },
  {
    name: 'Sicherheit',
    extraSlugs: ['fire_extinguisher', 'first_aid_kit', 'safety_box', 'alarm_system']
  }
]

const figuresMapping = {
  'battery': ['battery_capacity_in_ah'],
  'inverter': ['inverter_in_w'],
  'solar': ['solar_in_w'],
  'gas_tank': ['gas_vol_in_l'],
  'hot_water': ['boiler_vol_in_l'],
  'water_tank': ['clean_water_tank_in_l', 'greywater_tank_in_l'],
}

const figureDimensionsMapping = {
  'battery_capacity_in_ah': 'Ah',
  'inverter_in_w': 'W',
  'solar_in_w': 'W',
  'gas_vol_in_l': 'l',
  'boiler_vol_in_l': 'l',
  'clean_water_tank_in_l': 'l | Frisch ',
  'greywater_tank_in_l': 'l | Grau',
}

export function extraMapping(extras: Array<VehicleExtra | Extra> | undefined, customMapping: Array<object> | undefined = undefined) {
  if (!extras) return []
  const _mapping = customMapping || mapping
  const _processed_slugs: Array<string> = []
  const result: Array<object> = []

  _mapping.forEach(mappingObj => {
    const _filtered_extras = extras.filter(
      obj => {
        const slug = obj['value'] || obj['slug']
        return mappingObj['extraSlugs'].includes(slug)
      }
    )

    _filtered_extras.map(obj => {
      const slug = obj['value'] || obj['slug']
      return slug ? _processed_slugs.push(slug) : undefined
    })
    result.push({
      name: mappingObj['name'],
      extras: _filtered_extras,
    })
  })


  return [
    ...result,
    {
      name: 'Weitere',
      extras: extras.filter(obj => {
        const slug = obj['value'] || obj['slug']
        return !_processed_slugs.includes(slug)
      })
    }
  ]
}

export function getExtraFigures(extraSlug: string, advert: Advert): Array<object> | undefined {
  if (!Object.keys(figuresMapping).includes(extraSlug) || !advert) return
  const names = figuresMapping[extraSlug]
  const r: Array<object> = []
  names.forEach(fieldName => {
    if (advert.vehicle.figures[fieldName]) {
      r.push({
        field: fieldName,
        value: advert.vehicle.figures[fieldName],
        dimension: figureDimensionsMapping[fieldName]
      })
    }
  })
  return r
}

export function getPageUrl(pageName, router) {
  try {
    return `${process.env.BASE_URL}${router.resolve({name: pageName}).fullPath}`
  } catch (err) {
    console.error(err)
    return
  }
}

export const getWidgetIFrameUrl = function (uuid, router) {
  const path = router.resolve(
    {name: 'widget-advert-list', params: {widgetUuid: uuid}}
  ).fullPath
  const baseUrl = process.env.BASE_URL
  if (!baseUrl) return ''
  return new URL(path, baseUrl).href
}

export const getWidgetIFrame = function (uuid, router) {
  const url = getWidgetIFrameUrl(uuid, router)
  return `<iframe src="${url}" style="border: none; margin: 0; width: 100%; display: block; height: 100vh"></iframe>`
}

export const randomIntFromInterval = function (min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const humanizeDate = (dateVal) => {
  const date = new Date(dateVal)
  return formatDistance(date, new Date(), {addSuffix: false, locale: de})
}

export const humanizeSeconds = function (seconds: number) {
  const days = Math.floor(seconds / 86400);
  seconds %= 86400;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  if (days > 0) {
    if (hours > 0) {
      return `${days}d ${hours}h`;
    } else if (minutes > 0) {
      return `${days}d ${minutes}min`;
    } else {
      return `${days}d ${seconds}s`;
    }
  } else if (hours > 0) {
    if (minutes > 0) {
      return `${hours}h ${minutes}min`;
    } else {
      return `${hours}h ${seconds}s`;
    }
  } else {
    return `${minutes}min ${seconds}s`;
  }
}


export function showLoginDialog() {
  Dialog.create({
    component: LoginSignupDialog,
    componentProps: {
    }
  })
}


export const toggleAdvertFavorite = (advert) => {
  return Adverts.toggleUserFavorite(advert.uuid).then(resp => {
    advert.is_favorite = resp.status === 201
    return resp
  }).catch(err => {
    if (err.response.status === 401) {
      showLoginDialog()
      return
    }
    return err
  })
}

export function registerDevice(messaging: any = undefined) {
  if (!navigator?.serviceWorker) {
    console.debug('navigator and/or serviceWorker not defined')
    return
  }

  const mainStore = useMainStore()
  let _messaging = messaging
  if (!messaging) {
    try {
      _messaging = getMessaging(firebase)
    } catch (err) {
      console.error(err)
      return
    }
  }

  return getToken(_messaging, {vapidKey: process.env.FCM_VAPID_KEY}).then(token => {
    console.debug('firebase token', token)
    const data = {
      registration_id: token.toString(),
      active: true,
      type: 'web',
    }
    if (mainStore.accountDetail) {
      api.post('devices/', data).then(() => {
        console.debug('Notifications granted')
      }).catch((err) => {
        console.error(err)
      })
    }
    return token
  }).catch((err) => {
    console.error(err)
    return err
  })
}

const mapExtraFigures = {
  'solar': {
    key: 'vehicle__figures__solar_in_w',
    unit: 'W',
  },
  'water_tank': {
    key: 'vehicle__figures__clean_water_tank_in_l',
    unit: 'l',
  },
  'inverter': {
    key: 'vehicle__figures__inverter_in_w',
    unit: 'W',
  },
  'battery': {
    key: 'vehicle__figures__battery_capacity_in_ah',
    unit: 'Ah',
  },
  'gas_tank': {
    key: 'vehicle__figures__gas_vol_in_l',
    unit: 'l',
  },
  'hot_water': {
    key: 'vehicle__figures__boiler_vol_in_l',
    unit: 'l',
  },

}
export const getChipLabelExtraFigureKeys = function (key) {
  if (!mapExtraFigures.hasOwnProperty(key)) return
  const fig = mapExtraFigures[key]

  return {
    minKey: `${fig.key}_min`,
    maxKey: `${fig.key}_max`,
    unit: fig.unit
  }
}

export const setBusinessAccount = async function (slug: string | null, router: any = undefined) {
  const mainStore = useMainStore()
  const subStore = useSubscriptionStore()
  const isChange = mainStore.accountDetail?.active_account?.slug !== slug

  if (isChange) {
    if (slug) {
      try {
        await mainStore.fetchBusiness(slug)
      } catch (err) {
        const {response} = err as any

        if ([404, 400].includes(response?.status)) {
          router.replace({name: '404'});
          return
        }
      }
    }

    Loading.show({
      message: 'Profil wird gewechselt ...',
      spinner: QSpinnerGears
    })
    await mainStore.setActiveAccount(slug)
    subStore.fetchAccountSubscription()
    subStore.fetchAccountQuota()
  }

  if (router) {
    router.push(getAccountPageParams())
  }
  Loading.hide()
}

export function getAccountPageParams(mainStoreInstance: Store | undefined = undefined): object | string {
  let mainStore: any = mainStoreInstance
  if (!mainStoreInstance) {
    mainStore = useMainStore()
  }
  if (!mainStore.accountDetail?.active_account) return '/'
  if (mainStore.accountDetail?.active_account.is_business) {
    return {name: 'business-account', params: {slug: mainStore.accountDetail?.active_account?.slug}}
  } else {
    return {name: 'account'}
  }
}

export function limitReachedRoute(limitType: string) {
  const mainStore = useMainStore()
  if (mainStore.accountDetail?.active_account.is_business) {
    return {name: 'pricing-commercial-use-tab', query: {limit: limitType}}
  }
  return {name: 'pricing', query: {limit: limitType}}
}

export function updateParams(route, router, newParams, merge = true) {
  // Create a copy of the existing query parameters
  let updatedParams = {}
  if (merge) {
    updatedParams = {...route.query, ...newParams};
  } else {
    updatedParams = newParams
  }
  // Use the router to navigate to the same route with new parameters
  router.replace({name: route.name?.toString(), query: updatedParams});
}

export function extractAllPaths(router): Array<string> {
  // Initialize an empty array to hold all paths
  const paths: Array<string> = [];

  // Nested function to recursively add paths from routes and their children
  function addPaths(routes: RouteRecordRaw[]) {
    routes.forEach(route => {
      if (route?.path) {
        paths.push(route.path);  // Add the path to the paths array
      }
      if (route?.children) {
        addPaths(route.children);  // Recursively add paths from children
      }
    });
  }

  // Start adding paths from the root routes
  addPaths(router.getRoutes().filter(obj => obj.aliasOf === undefined));
  return paths;  // Return the collected paths
}

export async function reloadApp() {
  let isCapactior
  try {
    const {Capacitor} = await import('@capacitor/core')
    isCapactior = Capacitor.isNativePlatform()
  } catch (e) {
    console.error(e)
  }
  if (isCapactior) {
    document.location.href = 'index.html';
  } else {
    window.location.reload()
  }
}

export function isSubset(dict1: Record<string, any>, dict2: Record<string, any>): boolean {
  for (const key in dict1) {
    if (!dict2.hasOwnProperty(key)) {
      return false;
    }

    if (Array.isArray(dict1[key])) {
      // Check if every item in the dict1 array is included in the dict2 array
      if (!Array.isArray(dict2[key]) || !dict1[key].every(item => dict2[key].includes(item))) {
        return false;
      }
    } else if (typeof dict1[key] === 'object' && dict1[key] !== null) {
      // Recursive check for nested objects
      if (!isSubset(dict1[key], dict2[key])) {
        return false;
      }
    } else {
      // Check for primitive value equality
      if (dict1[key] !== dict2[key]) {
        return false;
      }
    }
  }
  return true;
}

export function randomString(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
