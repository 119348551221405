<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <base-dialog-content style="max-height: 95dvh">
      <div class="q-pb-lg">
        <login-form :next="$route.path.toString()"></login-form>
      </div>
    </base-dialog-content>
  </q-dialog>
</template>

<script setup>
import {useDialogPluginComponent} from 'quasar'
import LoginForm from 'src/apps/auth/components/LoginForm.vue';
import BaseDialogContent from 'src/apps/core/components/BaseDialogContent.vue';


defineEmits([
  ...useDialogPluginComponent.emits
])

const {dialogRef, onDialogHide} = useDialogPluginComponent()
</script>
