import * as Sentry from '@sentry/vue';
import {boot} from 'quasar/wrappers';


export default boot(({app, router}) => {
  console.debug('Sentry is on')
  Sentry.init({
    app,
    environment: process.env.ENVIRONMENT,
    dsn: process.env.SENTRY_DSN_URL,
    integrations: [
      Sentry.browserTracingIntegration({router}),
      Sentry.replayIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'testing.campertrader.de', 'staging.campertrader.de', 'campertrader.de', /^\//],
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  })
});
