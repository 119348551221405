import {RouteRecordRaw} from 'vue-router'
import Auth from 'src/router/routes/auth'
import Advert from 'src/router/routes/advert'
import Landing from 'src/router/routes/landing';
import Tools from 'src/router/routes/tools';
import Business from 'src/router/routes/business';
import Widgets from 'src/router/routes/widgets';
import Subscription from 'src/apps/subscription/routes';
import Payment from 'src/router/routes/payment';
import Messenger from 'src/apps/messenger/routes'
import Search from 'src/apps/search/routes'

let routes: RouteRecordRaw[] = []

routes = routes.concat([
  {
    path: '',
    component: () => import('layouts/IndexLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/IndexPage.vue'),
        name: 'home'
      },
    ],
  },
  {
    path: '',
    component: () => import('src/apps/search/layouts/SearchWrapperLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('layouts/AppMainLayout.vue'),
        children: [
          {
            path: '/suche',
            alias: '/search',
            component: () => import('src/apps/search/pages/SearchPage.vue'),
            name: 'search',
            meta: {noHeader: true}
          },
          {
            path: '/karte',
            alias: '/map',
            component: () => import('pages/MapPage.vue'),
            name: 'map',
            meta: {noFooter: true, noFooterBar: true, noHeader: true}
          },
        ]
      },
    ]
  },

  {
    path: '',
    component: () => import('layouts/AppMainLayout.vue'),
    children: [
      {
        path: '/favoriten',
        alias: '/favorites',
        component: () => import('pages/FavoritesPage.vue'),
        name: 'favorites',
        meta: {loginRequired: true}
      },
    ]
  },

  {
    path: '',
    component: () => import('layouts/AppLayoutOld.vue'),
    children: [
      {
        path: '/ueber-uns',
        alias: '/about-us',
        component: () => import('pages/static/AboutUsPage.vue'),
        name: 'about-us',
        meta: {hasBackBtn: true}
      },
      {
        path: '/partner',
        component: () => import('pages/static/PartnerPage.vue'),
        name: 'partner',
        meta: {hasBackBtn: true}
      },
      {
        path: '/faq-hilfe',
        alias: '/faq-help',
        component: () => import('pages/static/FaqAndHelpPage.vue'),
        name: 'faq-help',
        meta: {hasBackBtn: true}
      },
      {
        path: '/datenschutz',
        alias: '/privacy',
        component: () => import('pages/static/PrivacyPage.vue'),
        name: 'privacy',
        meta: {hasBackBtn: true}
      },
      {
        path: '/impressum',
        alias: '/imprint',
        component: () => import('pages/static/ImprintPage.vue'),
        name: 'imprint',
        meta: {hasBackBtn: true}
      },
      {
        path: '/kontakt',
        alias: '/contact',
        component: () => import('pages/static/ContactPage.vue'),
        name: 'contact',
        meta: {hasBackBtn: true}
      },
    ],
  },

  {
    path: '',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '/haendler-werden',
        alias: '/dealer',
        component: () => import('pages/static/landingPages/BusinessLandingPage.vue'),
        name: 'dealer-landing',
      },
    ]
  },

  {
    path: '',
    component: () => import('layouts/BackBtnLayout.vue'),
    children: [
      {
        path: '/link-tree',
        component: () => import('pages/LinkTreePage.vue'),
        name: 'link-tree',
        meta: {}
      },
    ]

  }
])

routes = routes.concat(Auth)
routes = routes.concat(Advert)
routes = routes.concat(Subscription)
routes = routes.concat(Landing)
routes = routes.concat(Tools)
routes = routes.concat(Business)
routes = routes.concat(Widgets)
routes = routes.concat(Payment)
routes = routes.concat(Messenger)
routes = routes.concat(Search)

routes = routes.concat([
  // Always leave this as last one,
  {
    path: '',
    component: () => import('layouts/BackBtnLayout.vue'),
    children: [
      {
        path: '/redirect',
        component: () => import('pages/ExternalLinkRedirect.vue'),
        name: 'redirect',
      },
    ]
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
    name: 'not-found',
  },
  {
    path: '/error-404',
    component: () => import('pages/ErrorNotFound.vue'),
    name: '404',
  },
  {
    path: '/error/:code?/',
    component: () => import('pages/ErrorPage.vue'),
    name: 'error',
  },
  {
    path: '/test/error',
    component: () => import('pages/TestErrorPage.vue'),
    name: 'test-error',
  },
])

routes.push({
  path: '/download-app',
  component: () => import('src/apps/core/pages/AppDownloadRedirect.vue'),
  name: 'download-app'
})

if (process.env.DEV) {
  routes.push({
    path: '/test/component',
    component: () => import('pages/TestComponent.vue')
  })

  routes.push({
      path: '',
      component: () => import('layouts/BackBtnLayout.vue'),
      children: [
        {
          path: '/dev',
          name: 'developer',
          component: () => import('src/pages/DevPage.vue')
        },

        {
          path: '/gewinnspiel/contest-summer-special-2024',
          component: () => import('src/apps/etc/pages/ContestPage.vue'),
          name: 'contest-summer-special-2024',
          meta: {loginRequired: true}
        }
      ],
    }
  )

}

export default routes
