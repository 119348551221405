export default [
  {
    path: '',
    component: () => import('layouts/BackBtnNarrowLayout.vue'),
    children: [
      {
        path: '/registrieren',
        alias: '/signup',
        component: () => import('src/apps/auth/pages/SignupPage.vue'),
        name: 'signup-private',
        meta: {hasBackBtn: true}
      },
      {
        path: '/registrieren/email',
        name: 'signup-email-verification',
        component: () => import('src/apps/auth/pages/VerifyAccountPage.vue'),
        meta: {hasBackBtn: true}
      },
      {
        path: '/login',
        alias: '/auth/login',
        component: () => import('src/pages/auth/LoginPage.vue'),
        name: 'login',
        meta: {hasBackBtn: true}
      },
      {
        path: '/auth/reset',
        component: () => import('pages/auth/PasswordResetPage.vue'),
        name: 'reset',
        meta: {hasBackBtn: true}
      },
      {
        path: '/auth/reset/confirm/:uid/:token',
        component: () => import('pages/auth/PasswordResetConfirmPage.vue'),
        name: 'reset-confirm'
      },
      {path: '/auth/verify-email/:key/', component: () => import('pages/auth/VerifyPage.vue'), name: 'verify'},
      {
        path: '/auth/password/change/',
        component: () => import('pages/auth/PasswordChangePage.vue'),
        name: 'password-change',
        meta: {loginRequired: true}
      },
    ]
  },
  {
    path: '',
    component: () => import('layouts/AppMainLayout.vue'),
    meta: {noFooter: true, noHeader: true, noFooterBar: true},
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            component: () => import('src/apps/core/pages/PricingPage.vue'),
            children: [
              {
                path: '/auth/signup/subscription/privat/',
                alias: '/auth/signup/subscription/',
                component: () => import('src/apps/core/pages/components/PersonalUseTab.vue'),
                name: 'pricing',
              },
              {
                path: '/auth/signup/subscription/gewerblich/',
                component: () => import('src/apps/core/pages/components/CommercialPlans.vue'),
                name: 'pricing-commercial-use-tab'
              }

            ]
          },
        ],
      },
      {
        path: '/registrieren/gewerblich',
        alias: '/signup/commercial/',
        name: 'signup-commercial',
        component: () => import('src/apps/auth/pages/SignupBusiness.vue'),
        meta: {hasBackBtn: true}
      },
    ]
  },

  {
    path: '',
    component: () => import('layouts/AppMainLayout.vue'),
    children: [
      {
        path: '/auth/account',
        component: () => import('pages/auth/AccountPage.vue'),
        name: 'account',
        meta: {loginRequired: true, noFooter: true}
      },

      {
        path: '/auth/social/:provider?/',
        component: () => import('src/apps/auth/pages/SocialAuthPage.vue'),
        name: 'social-auth',
        meta: {noFooter: true, noHeader: true, noFooterBar: true}
      }
    ],
  },
  {
    path: '',
    component: () => import('layouts/BackBtnLayout.vue'),
    children: [
      {
        path: '/auth/account/settings',
        component: () => import('pages/auth/SettingsPage.vue'),
        name: 'settings',
        meta: {loginRequired: true, noFooter: true}
      },
    ],
  },
  {
    path: '',
    component: () => import('layouts/BackBtnLayout.vue'),
    children: [
      {
        path: '/profile/:uuid',
        component: () => import('pages/auth/PublicProfilePage.vue'),
        name: 'profile',
        meta: {hasBackBtn: true}
      }
    ]
  }
]
