export default [
  {
    path: '',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '/suche/:carType/',
        component: () => import('pages/FilterPage.vue'),
        name: 'landing-carType',
      },
      {
        path: '/suche/:carType/umkreis-:city/',
        component: () => import('pages/FilterPage.vue'),
        name: 'landing-carType-city',
      },
      {
        path: '/suche/umkreis-:city/',
        component: () => import('pages/FilterPage.vue'),
        name: 'landing-city',
      },
    ],
  },

]
