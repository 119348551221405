import {getMessaging, onMessage} from 'firebase/messaging';
import {Notify} from 'quasar';
import {firebase} from 'boot/firebase';

export function setUpNotifications(router, route) {
  const messaging = getMessaging(firebase)

  onMessage(messaging, (payload) => {
    switch (payload.data?.type) {
      case 'new_message':
        if (route.matched.some(({name}) => ['messenger', 'messenger-chat'].includes(name))) return
        Notify.create({
          caption: payload.data?.body,
          message: payload.data?.title,
          group: false,
          avatar: payload.data.avatar,
          position: 'bottom-left',
          badgeStyle: {minWidth: '500px'},
          timeout: 10000,
          progress: true,
          multiLine: true,
          actions: [
            {label: 'Schließen', flat: true, size: 'md', color: 'grey-6'},
            {
              label: 'Zum Chat', flat: true, size: 'md', handler: () => {
                router.push({name: 'messenger-chat', params: {chatUuid: payload.data?.chat_uuid}})
              }
            },
          ]
        })
    }
  })
}
