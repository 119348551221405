import {Extra} from 'src/models/vehicle';
import {CapacitorBrowser, CapacitorCore} from 'boot/inject-capacitor';

export const toBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result as string)
      } else {
        reject(new Error('FileReader result is empty'))
      }
    }

    reader.onerror = (error) => {
      console.error('Error reading file:', error)
      reject(new Error(`Error reading file: ${JSON.stringify(error)}`))
    }

    reader.onabort = () => {
      reject(new Error('File reading was aborted'))
    }

    reader.onloadend = () => {
      if (reader.error) {
        reject(new Error(`FileReader error: ${reader.error.message}`))
      }
    }
  })
}


export async function openExternalLink(url: string) {
  if (CapacitorCore?.isNative) {
    await CapacitorBrowser.open({url: url})
    return
  }
  window.open(url, '_blank')
}

export async function findExtrasInText(text: string, extras: Array<Extra>): Promise<Array<Extra>> {
  return new Promise((resolve) => {
    if (!text || !extras?.length) {
      resolve([])
      return
    }

    const r: Array<Extra> = []
    const _text = text.toLowerCase()

    for (const extra of extras) {
      if (!extra.name) continue

      if (_text.includes(extra.name.toString().toLowerCase())) {
        r.push(extra)
      }
    }
    resolve(r)
  })
}

export function truncate(text, length, clamp = '...') {
  if (!text) return
  text = text.replace(/\r\n/g, '\n')
  return text?.length > length ? text.slice(0, length) + clamp : text;
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}


export function getCityFromNominatim(address: any) {
  return address.city || address.town || address.village || 'Unbekannt'
}

export function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
